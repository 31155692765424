/* eslint-disable */
import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../../components/Layout';
import SEO from '../../components/Other/seo';

const daysOld = 8;

const getDaysDifference = date => {
	const postDate = new Date(date);
	const today = Date.now();
	return (today - postDate) / (1000 * 60 * 60 * 24);
};

const IndexPage = props => {
	const postList = props.data.allMarkdownRemark;

	return (
		<Layout>
			<section className='col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12'>
				<SEO title='Florin Pop - Blog' />
				<h1>
					Blog Posts
					<button className='btn-tag center-tag'>
						<Link to='/tags'>View all tags</Link>
					</button>
				</h1>
				<div className='blog-list'>
					{postList.edges.map(({ node }, i) => (
						<div className='blog row' key={i}>
							<div className='col-xs-12'>
								<Link to={node.fields.slug} className='blog-link'>
									{getDaysDifference(node.frontmatter.date) < daysOld && (
										<span className='new-tag'>NEW</span>
									)}
									<h6>{node.frontmatter.date}</h6>
									<h2 className='blog-title'>{node.frontmatter.title}</h2>
									<div className='blog-excerpt'>
										<p>{node.excerpt}</p>
									</div>
								</Link>
							</div>
						</div>
					))}
				</div>
			</section>
		</Layout>
	);
};

export default IndexPage;
export const listQuery = graphql`
	query ListQuery {
		allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
			edges {
				node {
					fields {
						slug
					}
					excerpt(pruneLength: 250)
					frontmatter {
						date(formatString: "MMMM DD YYYY")
						title
					}
				}
			}
		}
	}
`;
